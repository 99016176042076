import utils from '../../../../../Shared/Utils/utils';
import trialsIcon from '../../../../../../assets/images/NumberOfTrialsIcon.svg';
import pubsIcon from '../../../../../../assets/images/NumberOfTrialsWithPublicationsIcon.svg';
import resultsIcon from '../../../../../../assets/images/NumberOfTrialsWithREsultsPostedIcon.svg';
import { Chip } from '@mui/material';
import Grid from '@mui/material/Grid';

function splitCamelcaseString(string: string): string {
    return string.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
}

function toTitleText(text: string): string {
    return text
        .toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
}

const aggregateTrialDates = async (result: any, data: any, type: string) => {
    let updatedData: any[] = result;
    data.map((item: { [x: string]: number; _id: any; count: number }) => {
        const formattedDate = utils.formatDate(item._id, 'MMM YYYY');
        const yearIndex = updatedData.findIndex((e) => e.groupedBy == formattedDate);
        if (yearIndex === -1) {
            updatedData.push({
                groupedBy: formattedDate,
                [type]: item.count ? item.count : null,
            });
        } else {
            const currentData = updatedData[yearIndex];
            updatedData.splice(yearIndex, 1, {
                ...currentData,
                [type]: item.count ? item.count : null,
            });
        }
    });
    updatedData.sort((a, b) => a.groupedBy - b.groupedBy);
    return updatedData;
};

const aggregateSponsorStatus = async (result: any, data: any, type: string) => {
    let updatedData: any[] = result;
    data.map((item: { [x: string]: number; _id: any; count: number }) => {
        const sponsorIndex = updatedData.findIndex((e) => e.groupedBy == item._id);
        if (sponsorIndex === -1) {
            updatedData.push({
                groupedBy: item._id,
                [type]: item.count ? item.count : null,
            });
        } else {
            const currentData = updatedData[sponsorIndex];
            updatedData.splice(sponsorIndex, 1, {
                ...currentData,
                [type]: item.count ? item.count : null,
            });
        }
    });
    updatedData.sort((a, b) => a.groupedBy - b.groupedBy);
    return updatedData;
};

const aggregateStatusType = async (result: any, data: any) => {
    let updatedData: any[] = result;
    data.map((item: { [x: string]: number; _id: any; count: number }) => {
        let formattedId = item._id.replace('_', ' ').toLowerCase();
        if (formattedId.includes('active')) {
            formattedId = 'active, not recruiting';
        }
        if (formattedId.includes('unknown')) {
            formattedId = 'unknown status';
        }
        const statusIndex = updatedData.findIndex((e) => e.id == formattedId);
        if (statusIndex === -1) {
            updatedData.push({
                id: formattedId || 'Unknown',
                label: formattedId || 'Unknown',
                value: item.count,
            });
        } else {
            const currentData = updatedData[statusIndex];
            updatedData.splice(statusIndex, 1, {
                ...currentData,
                value: currentData.value + item.count,
            });
        }
    });
    updatedData.sort((a, b) => a.groupedBy - b.groupedBy);
    return updatedData;
};

export const dashboardBuild = (setFilters: any, dashboard: any) => {
    return [
        {
            type: 'iconCount',
            width: 4,
            count: {
                title: 'Number of Trials',
                color: 'linear-gradient(to right bottom, #325599 40%, #32559999) !important',
                image: trialsIcon,
            },
            dataTransformer: (data: any, chart: any) =>
                data && data.items && data.items.length > 0 && data.items[0].count.total.toLocaleString(),
            dataType: 'reportCounts',
            includeChartTitle: false,
            height: 200,
        },
        {
            type: 'iconCount',
            width: 4,
            count: {
                title: 'Number of Trials Recruiting',
                color: 'linear-gradient(to right bottom, #663299 40%, #66329999) !important',
                image: pubsIcon,
            },
            dataTransformer: (data: any, chart: any) => {
                return data && data.items && data.items.length > 0 && data.items[0].count.total.toLocaleString();
            },
            dataType: 'recruitingCount',
            includeChartTitle: false,
            height: 200,
        },
        {
            type: 'iconCount',
            width: 4,
            count: {
                title: `Number of Trials with Results Posted`,
                color: 'linear-gradient(to right bottom, #008577 40%, #00857799) !important',
                image: resultsIcon,
            },
            dataTransformer: (data: any, chart: any) => {
                return data && data.items && data.items.length > 0 && data.items[0].count.total.toLocaleString();
            },
            dataType: 'trialsWithResults',
            includeChartTitle: false,
            main: 'COUNT',
            height: 200,
        },
        {
            type: 'barChart',
            layout: 'vertical',
            groupMode: 'grouped',
            height: 650,
            width: 12,
            chartTitle: 'Quarterly Notable Trial Date Trends',
            showBarValue: false,
            withBrush: true,
            dataType: 'reportCounts',
            downloadableImage: true,
            downloadableCsv: true,
            yLegend: 'Number of Cancer Trials',
            aggregatedDataFilter: true,
            colourScheme: ['#325599', '#FF6B81', '#FFC46B'],
            customFilterAggregation: (data: any, item: string) => {
                const formatDate = utils.formatDate(data, 'YYYY-MM-DD');
                const date = new Date(formatDate);
                const getQuarter = new Date(date.setMonth(date.getMonth() + 3));
                const filter = {
                    [item]: {
                        id: item,
                        type: 'date',
                        values: [formatDate, utils.formatDate(getQuarter, 'YYYY-MM-DD')],
                    },
                };
                setFilters(filter);
            },
            dataTransformer: (data: any, chart: any) => {
                let result: any[] = [];
                const keys = ['start_date', 'primary_completion_date', 'completion_date'];
                if (
                    data &&
                    data.items &&
                    data.items.length > 0 &&
                    data.items[0].facet &&
                    data.items[0].facet.start_dateFacet
                ) {
                    const dateData = [
                        {
                            type: 'start_date',
                            data: data.items[0].facet.start_dateFacet.buckets,
                        },
                        {
                            type: 'primary_completion_date',
                            data: data.items[0].facet.primary_completion_dateFacet.buckets,
                        },
                        {
                            type: 'completion_date',
                            data: data.items[0].facet.completion_dateFacet.buckets,
                        },
                    ];
                    dateData.map((item) => {
                        aggregateTrialDates(result, item.data, item.type).then((res) => {
                            result = {
                                ...result,
                                [item.type]: res,
                            };
                        });
                    });
                }
                return {
                    data: result,
                    keys,
                };
            },
        },
        {
            type: 'map',
            dataTransformer: (data: any, chart: any) => {
                let result: any[][] = [];
                if (
                    data &&
                    data.items &&
                    data.items[0].facet &&
                    data.items[0].facet['Facilities.cityFacet'] &&
                    data.items[0].facet['Facilities.cityFacet'].buckets
                ) {
                    data.items[0].facet['Facilities.cityFacet'].buckets.map((item: any) => {
                        const world_coords = dashboard && dashboard.coordinates;
                        if (world_coords && world_coords.length) {
                            let coordsMatch: any = world_coords.find((o: { city: any }) => o.city === item._id);
                            if (coordsMatch) {
                                const tooltipDetail = `${coordsMatch.city}, ${coordsMatch.country}  - ${item.count} Trials Recruiting`;
                                result.push([coordsMatch.lat, coordsMatch.lng, tooltipDetail]);
                            }
                        }
                    });
                }
                return result;
            },
            downloadableImage: true,
            chartTitle: 'Top Trial Locations Currently Recruiting',
            width: '8',
            mapType: 'marker',
            popup: true,
            center: ['20', '10'],
            zoom: '2.4',
            dataType: 'facilityQuery',
            latitudeField: 'lat',
            longitudeField: 'lng',
            queryOnMapChange: false,
        },
        {
            type: 'barChart',
            layout: 'horizontal',
            width: 4,
            chartTitle: '',
            tickAmount: 0,
            xAxisRotate: false,
            showXLabels: false,
            dataTransformer: (data: any, chart: any) => {
                let result = {};
                if (
                    data &&
                    data.items &&
                    data.items.length > 0 &&
                    data.items[0].facet &&
                    data.items[0].facet['Facilities.cityFacet']
                ) {
                    result = {
                        data: data.items[0].facet['Facilities.cityFacet'].buckets.map((v: any) => ({
                            groupedBy: v['_id'],
                            Count: v.count,
                        })),
                        keys: ['Count'],
                    };
                }
                return result;
            },
            dataType: 'facilityQuery',
            downloadableImage: true,
            downloadableCsv: true,
            groupBy: 'Facilities.city',
            withBrush: true,
        },
        {
            type: 'barChart',
            width: 6,
            layout: 'horizontal',
            chartTitle: 'Top 50 Sponsors',
            tickAmount: 0,
            xAxisRotate: false,
            showXLabels: false,
            dataTransformer: (data: any, chart: any) => {
                let result = {};
                if (
                    data &&
                    data.items &&
                    data.items.length > 0 &&
                    data.items[0].facet &&
                    data.items[0].facet.SponsorFacet
                ) {
                    result = {
                        data: data.items[0].facet.SponsorFacet.buckets.map((v: any) => ({
                            groupedBy: v['_id'],
                            Count: v.count,
                        })),
                        keys: ['Count'],
                    };
                }
                return result;
            },
            dataType: 'reportCounts',
            legendAnchor: 'bottom-right',
            downloadableImage: true,
            downloadableCsv: true,
            enableGridX: true,
            withBrush: true,
            groupBy: 'Sponsor',
        },
        {
            type: 'barChart',
            layout: 'horizontal',
            width: 6,
            chartTitle: 'Trial Count by Phase',
            tickAmount: 0,
            xAxisRotate: false,
            showXLabels: false,
            dataTransformer: (data: any, chart: any) => {
                let result = {};
                if (
                    data &&
                    data.items &&
                    data.items.length > 0 &&
                    data.items[0].facet &&
                    data.items[0].facet.phaseFacet
                ) {
                    result = {
                        data: data.items[0].facet.phaseFacet.buckets.map((v: any) => ({
                            groupedBy: toTitleText(v['_id'].toLowerCase().replace('_', ' ')),
                            Count: v.count,
                        })),
                        keys: ['Count'],
                    };
                }
                return result;
            },
            dataType: 'reportCounts',
            downloadableImage: true,
            downloadableCsv: true,
            groupBy: 'phase',
        },
    ];
};

export const insightsBuild = (setFilters: any, dashboard: any) => [
    {
        type: 'barChart',
        width: 8,
        layout: 'horizontal',
        chartTitle: 'Top Trial Counts by Condition',
        tickAmount: 0,
        xAxisRotate: false,
        showXLabels: false,
        dataTransformer: (data: any, chart: any) => {
            let result = {};
            if (
                data &&
                data.items &&
                data.items.length > 0 &&
                data.items[0].facet &&
                data.items[0].facet.conditionFacet
            ) {
                result = {
                    data: data.items[0].facet.conditionFacet.buckets.map((v: any) => ({
                        groupedBy: toTitleText(v['_id'].toLowerCase()),
                        Count: v.count,
                    })),
                    keys: ['Count'],
                };
            }
            return result;
        },
        dataType: 'facets',
        legendAnchor: 'bottom-right',
        downloadableImage: true,
        downloadableCsv: true,
        enableGridX: true,
        withBrush: true,
        groupBy: 'condition',
    },
    {
        type: 'pieChart',
        width: 4,
        chartType: 'donut',
        chartTitle: 'Study Status',
        dataTransformer: (data: any) => {
            const arrayObj =
                data &&
                data.items &&
                data.items.length > 0 &&
                data.items[0].facet.overall_statusFacet.buckets.map((item: any) => ({
                    id: item['_id'] || 'Unknown',
                    label: item['_id'] || 'Unknown',
                    value: item.count,
                }));
            return arrayObj;
        },
        dataType: 'facets',
        showPercent: true,
        downloadableImage: true,
        downloadableCsv: true,
        legend: true,
        legendAnchor: 'top-left',
        enableRadialLabels: false,
        filterBy: 'overall_status',
    },
    {
        type: 'pieChart',
        width: 4,
        chartType: 'donut',
        chartTitle: 'Study Type',
        dataTransformer: (data: any) => {
            const arrayObj =
                data &&
                data.items &&
                data.items.length > 0 &&
                data.items[0].facet.study_typeFacet.buckets.map((item: any) => ({
                    id: item['_id'] || 'Unknown',
                    label: item['_id'] || 'Unknown',
                    value: item.count,
                }));
            return arrayObj;
        },
        dataType: 'facets',
        showPercent: true,
        downloadableImage: true,
        downloadableCsv: true,
        legend: true,
        legendAnchor: 'top-left',
        enableRadialLabels: false,
        filterBy: 'study_type',
    },
    {
        type: 'barChart',
        width: 8,
        layout: 'horizontal',
        chartTitle: 'Study Status by Sponsor',
        tickAmount: 0,
        xAxisRotate: false,
        showXLabels: false,
        aggregatedDataFilter: true,
        customFilterAggregation: (data: any, item: string) => {
            const filter = {
                Sponsor: {
                    id: 'Sponsor',
                    type: 'STRING',
                    operator: 'in',
                    values: [data],
                },
                overall_status: {
                    id: 'overall_status',
                    type: 'STRING',
                    operator: 'in',
                    values: [item],
                },
            };
            setFilters(filter);
        },
        dataTransformer: (data: any, chart: any) => {
            let result: any[] = [];
            const keys = [
                'Completed',
                'Recruiting',
                'Unknown status',
                'Terminated',
                'Active, not recruiting',
                'Not yet recruiting',
                'Withdrawn',
                'Enrolling by invitation',
                'Suspended',
                'No longer available',
            ];
            if (
                data &&
                data.items &&
                data.items.length > 0 &&
                data.items[0].facet &&
                data.items[0].facet.SponsorFacet
            ) {
                const dateData = [
                    {
                        type: 'Completed',
                        data: data.items[0].facet.SponsorFacet.buckets,
                    },
                    {
                        type: 'Recruiting',
                        data: dashboard.sponsorStatusRecruiting.items[0].facet.SponsorFacet.buckets,
                    },
                    {
                        type: 'Unknown status',
                        data: dashboard.sponsorStatusUnknown.items[0].facet.SponsorFacet.buckets,
                    },
                    {
                        type: 'Terminated',
                        data: dashboard.sponsorStatusTerminated.items[0].facet.SponsorFacet.buckets,
                    },
                    {
                        type: 'Active, not recruiting',
                        data: dashboard.sponsorStatusActiveNotRecruiting.items[0].facet.SponsorFacet.buckets,
                    },
                    {
                        type: 'Not yet recruiting',
                        data: dashboard.sponsorStatusNotYetRecruiting.items[0].facet.SponsorFacet.buckets,
                    },
                    {
                        type: 'Withdrawn',
                        data: dashboard.sponsorStatusWithdrawn.items[0].facet.SponsorFacet.buckets,
                    },
                    {
                        type: 'Enrolling by invitation',
                        data: dashboard.sponsorStatusByInvitation.items[0].facet.SponsorFacet.buckets,
                    },
                    {
                        type: 'Suspended',
                        data: dashboard.sponsorStatusSuspended.items[0].facet.SponsorFacet.buckets,
                    },
                    {
                        type: 'No longer available',
                        data: dashboard.sponsorStatusNotAvailable.items[0].facet.SponsorFacet.buckets,
                    },
                ];
                dateData.map((item) => {
                    aggregateSponsorStatus(result, item.data, item.type).then((res) => {
                        result = {
                            ...result,
                            [item.type]: res,
                        };
                    });
                });
            }
            return {
                data: result,
                keys,
            };
        },
        dataType: 'sponsorStatusCompleted',
        legendAnchor: 'bottom-right',
        downloadableImage: true,
        downloadableCsv: true,
        enableGridX: true,
        withBrush: true,
        groupBy: 'Sponsor',
    },
    {
        type: 'pieChart',
        width: 6,
        chartType: 'donut',
        chartTitle: 'Type of Intervention',
        dataTransformer: (data: any, fieldName: any) => {
            const arrayObj =
                data &&
                data.items &&
                data.items.length > 0 &&
                data.items[0].facet['interventions.intervention_typeFacet'].buckets.map((item: any) => ({
                    id: item['_id'] || 'Unknown',
                    label: item['_id'] || 'Unknown',
                    value: item.count,
                }));
            return arrayObj;
        },
        dataType: 'facets',
        showPercent: true,
        downloadableImage: true,
        downloadableCsv: true,
        legend: true,
        legendAnchor: 'top-left',
        enableRadialLabels: false,
        filterBy: 'interventions.intervention_type',
    },
    {
        type: 'wordCloud',
        width: 6,
        chartTitle: 'Top Treatment Procedures',
        dataTransformer: (data: any, chart: any) => {
            const arrayObj =
                data &&
                data.items &&
                data.items.length > 0 &&
                data.items[0].facet &&
                data.items[0].facet['PROCEDUREFacet'] &&
                data.items[0].facet['PROCEDUREFacet'].buckets.map((item: any) => ({
                    value: item['_id'],
                    count: item.count,
                }));
            return arrayObj;
        },
        dataType: 'facets',
        groupBy: 'PROCEDURE',
        downloadableImage: true,
        downloadableCsv: true,
    },
    {
        type: 'wordCloud',
        width: 6,
        chartTitle: 'Top Treatment Devices',
        dataTransformer: (data: any, chart: any) => {
            const arrayObj =
                data &&
                data.items &&
                data.items.length > 0 &&
                data.items[0].facet &&
                data.items[0].facet['DEVICEFacet'] &&
                data.items[0].facet['DEVICEFacet'].buckets.map((item: any) => ({
                    value: item['_id'],
                    count: item.count,
                }));
            return arrayObj;
        },
        dataType: 'facets',
        groupBy: 'DEVICE',
        downloadableImage: true,
        downloadableCsv: true,
    },
    {
        type: 'pieChart',
        width: 6,
        chartType: 'donut',
        chartTitle: 'Top 10 Drug Treatments in Trials',
        dataTransformer: (data: any, fieldName: any) => {
            const arrayObj =
                data &&
                data.items &&
                data.items.length > 0 &&
                data.items[0].facet.DRUGFacet.buckets.map((item: any) => ({
                    id: item['_id'] || 'Unknown',
                    label: item['_id'] || 'Unknown',
                    value: item.count,
                }));
            return arrayObj;
        },
        dataType: 'facets',
        showPercent: true,
        downloadableImage: true,
        downloadableCsv: true,
        legend: true,
        legendAnchor: 'top-left',
        enableRadialLabels: false,
        filterBy: 'DRUG',
    },
    // {
    //     type: 'divider'
    // },
    {
        type: 'pieChart',
        width: 4,
        chartType: 'donut',
        chartTitle: 'Trials by Sex',
        dataTransformer: (data: any, fieldName: any) => {
            const arrayObj =
                data &&
                data.items &&
                data.items.length > 0 &&
                data.items[0].facet.genderFacet.buckets.map((item: any) => ({
                    id: item['_id'] || 'Unknown',
                    label: item['_id'] || 'Unknown',
                    value: item.count,
                }));
            return arrayObj;
        },
        dataType: 'facets',
        showPercent: true,
        downloadableImage: true,
        downloadableCsv: true,
        legend: true,
        legendAnchor: 'top-left',
        enableRadialLabels: false,
        filterBy: 'gender',
    },
    {
        type: 'pieChart',
        width: 4,
        chartType: 'donut',
        chartTitle: 'Trials by Study Documents',
        dataTransformer: (data: any, fieldName: any) => {
            const arrayObj =
                data &&
                data.items &&
                data.items.length > 0 &&
                data.items[0].facet.information_typesFacet.buckets.map((item: any) => ({
                    id: item['_id'] || 'Unknown',
                    label: item['_id'] || 'Unknown',
                    value: item.count,
                }));
            return arrayObj;
        },
        dataType: 'facets',
        showPercent: true,
        downloadableImage: true,
        downloadableCsv: true,
        legend: true,
        legendAnchor: 'top-left',
        enableRadialLabels: false,
        filterBy: 'information_types',
    },
    {
        type: 'pieChart',
        width: 4,
        chartType: 'donut',
        chartTitle: 'Trials with Results',
        dataTransformer: (data: any, fieldName: any) => {
            const arrayObj =
                data &&
                data.items &&
                data.items.length > 0 &&
                data.items[0].facet.has_resultsFacet.buckets.map((item: any) => ({
                    id: item['_id'] || 'Unknown',
                    label: item['_id'] || 'Unknown',
                    value: item.count,
                }));
            return arrayObj;
        },
        dataType: 'facets',
        showPercent: true,
        downloadableImage: true,
        downloadableCsv: true,
        legend: true,
        legendAnchor: 'top-left',
        enableRadialLabels: false,
        filterBy: 'has_results',
    },
];

export const listBuild = [
    {
        type: 'tableComponent',
        width: 12,
        dataTransformer: (data: any, chart: any) => {
            let columns = chart.columns || [];
            const tableData: any[] = [];
            if (data && data.items && data.items.length > 0) {
                let keyNames: string[] = [];
                data.items.map((item: any, i: number) => {
                    const columnNames = Object.keys(item);
                    keyNames = Array.from(new Set([...keyNames, ...columnNames]));
                });
                const keys = keyNames.sort();
                if (!chart.columns) {
                    keys.forEach((key) => {
                        columns = [
                            {
                                name: 'brief_title',
                                label: 'Study Title',
                            },
                            {
                                name: 'nct_id',
                                label: 'NCT Number',
                            },
                            {
                                name: 'overall_status',
                                label: 'Status',
                                options: {
                                    customBodyRender: (value: any) => {
                                        return toTitleText(value.replaceAll('_', ' '));
                                    },
                                },
                            },
                            {
                                name: 'start_date',
                                label: 'Study Start Date',
                            },
                            {
                                name: 'primary_completion_date',
                                label: 'Primary Completion Date',
                                options: {
                                    display: false,
                                },
                            },
                            {
                                name: 'completion_date',
                                label: 'Study Completion Date',
                            },
                            {
                                name: 'country',
                                label: 'Location',
                            },
                            {
                                name: 'condition',
                                label: 'Conditions',
                                options: {
                                    customBodyRender: (value: any) => {
                                        if (value) {
                                            if (value.length > 5) {
                                                return (
                                                    value.slice(0, 5).join(', ') +
                                                    `,...  [see ${value.length - 5} more]`
                                                );
                                            } else {
                                                return value.join(', ');
                                            }
                                        } else {
                                            return 'N/A';
                                        }
                                    },
                                },
                            },
                            {
                                name: 'phase',
                                label: 'Phase',
                                options: {
                                    customBodyRender: (value: any) => {
                                        if (value) {
                                            return toTitleText(value.toLowerCase());
                                        } else {
                                            return 'N/A';
                                        }
                                    },
                                },
                            },
                            {
                                name: 'Sponsor',
                                label: 'Sponsor',
                            },
                            {
                                name: 'summary',
                                label: 'Summary',
                                options: {
                                    display: false,
                                },
                            },
                            {
                                name: 'condition',
                                label: 'Conditions',
                                options: {
                                    display: false,
                                },
                            },
                            {
                                name: 'pubMedArticles',
                                label: 'PubMed Articles',
                                options: {
                                    sort: false,
                                    customBodyRender: (value: any) => {
                                        let chipColor = 'rgba(0, 0, 0, 0.12)';
                                        let fontColor = '#000';
                                        if (value > 0) {
                                            chipColor = '#008577';
                                            fontColor = '#fff';
                                        }
                                        return (
                                            <Grid container justifyContent={'center'}>
                                                <Chip
                                                    label={value}
                                                    onClick={() => console.log('CLICK!')}
                                                    style={{
                                                        backgroundColor: chipColor,
                                                        color: fontColor,
                                                        fontWeight: value > 0 ? 'bold' : 'normal',
                                                    }}
                                                />
                                            </Grid>
                                        );
                                    },
                                },
                            },
                        ];
                    });
                }

                if (chart.extraColumns) {
                    Object.keys(chart.extraColumns).forEach((key) => {
                        columns.push({
                            name: key,
                            label: chart.extraColumns[key].label || splitCamelcaseString(key),
                        });
                    });
                }

                data.items.forEach((d: any) => {
                    const row = {
                        ...d,
                    };

                    if (chart.conversions) {
                        Object.keys(chart.conversions).forEach((k) => {
                            row[k] = chart.conversions[k](d[k]);
                        });
                    }

                    if (chart.extraColumns) {
                        Object.keys(chart.extraColumns).forEach((k) => {
                            row[k] = chart.extraColumns[k].content(d);
                        });
                    }

                    if (chart.idField) {
                        row._id = d[chart.idField];
                    } else {
                        delete row._id;
                    }

                    tableData.push(row);
                });
            }

            const returnData = {
                columns,
                data: tableData,
            };

            return returnData;
        },
        chartTitle: 'List Clinical Trials',
        dataType: 'tableData',
        download: false,
        downloadableCsv: true,
        elevation: 0,
        filter: false,
        print: false,
        pagination: true,
        search: false,
        selectableRows: false,
        sort: true,
        rowsPerPageOptions: [],
        viewColumns: false,
        serverSide: true,
        rowClick: true,
        idField: 'nct_id',
        showIdField: true,
        hideExport: true,
        conversions: {
            start_date: (d: string) => (d ? utils.formatDate(d, `MM/DD/YYYY`) : 'N/A'),
            primary_completion_date: (d: string) => (d ? utils.formatDate(d, `MM/DD/YYYY`) : 'N/A'),
            completion_date: (d: string) => (d ? utils.formatDate(d, `MM/DD/YYYY`) : 'N/A'),
            country: (d: string[]) => (d && d.join(', ')) || '',
            condition: (d: string[]) => {
                let conditions = [] as string[];
                d &&
                    d.map((i) =>
                        conditions.push(
                            i
                                .replace(/(\w)(\w*)/g, (_, firstChar, rest) => firstChar + rest.toLowerCase())
                                .replace("'S", "'s"),
                        ),
                    );
                return conditions;
            },
        },
    },
];

export const pubmedListBuild = [
    {
        type: 'tableComponent',
        width: 12,
        dataTransformer: (data: any, chart: any) => {
            let columns = chart.columns || [];
            const tableData: any[] = [];
            if (data && data.items && data.items.length > 0) {
                let keyNames: string[] = [];
                data.items.map((item: any, i: number) => {
                    const columnNames = Object.keys(item);
                    keyNames = Array.from(new Set([...keyNames, ...columnNames]));
                });
                const keys = keyNames.sort();
                if (!chart.columns) {
                    keys.forEach((key) => {
                        columns = [
                            {
                                name: 'title',
                                label: 'Article Title',
                            },
                            {
                                name: 'PMID',
                                label: 'PMID',
                            },
                            {
                                name: 'pubDate',
                                label: 'Publication Date',
                                options: {
                                    sort: false,
                                },
                            },
                            {
                                name: 'authors',
                                label: 'Authors',
                                sort: false,
                            },
                        ];
                    });
                }

                if (chart.extraColumns) {
                    Object.keys(chart.extraColumns).forEach((key) => {
                        columns.push({
                            name: key,
                            label: chart.extraColumns[key].label || splitCamelcaseString(key),
                        });
                    });
                }

                data.items.forEach((d: any) => {
                    const row = {
                        ...d,
                    };

                    if (chart.conversions) {
                        Object.keys(chart.conversions).forEach((k) => {
                            row[k] = chart.conversions[k](d[k]);
                        });
                    }

                    if (chart.extraColumns) {
                        Object.keys(chart.extraColumns).forEach((k) => {
                            row[k] = chart.extraColumns[k].content(d);
                        });
                    }

                    if (chart.idField) {
                        row._id = d[chart.idField];
                    } else {
                        delete row._id;
                    }

                    tableData.push(row);
                });
            }

            const returnData = {
                columns,
                data: tableData,
            };

            return returnData;
        },
        chartTitle: 'List Pubmed Articles',
        dataType: 'pubmedArticles',
        download: false,
        downloadableCsv: true,
        elevation: 0,
        filter: false,
        print: false,
        pagination: true,
        search: false,
        selectableRows: false,
        sort: true,
        rowsPerPageOptions: window.screen.width < 700 ? [] : [10, 25, 50],
        viewColumns: false,
        serverSide: true,
        rowClick: true,
        idField: 'PMID',
        showIdField: true,
        hideExport: true,
        conversions: {
            pubDate: (d: string) => utils.formatDate(d, `MM/DD/YYYY`),
            title: (d: string) => {
                if (typeof d === 'object') {
                    return d['#text'] || '';
                } else {
                    return d;
                }
            },
            authors: (d: any[]) => {
                if (d && d.length) {
                    return `${d[d.length - 1].name}`;
                }
                return '';
            },
        },
    },
];
