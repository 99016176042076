import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import logo from '../../assets/images/CTE_Logo.svg';
import trustIcon from '../../assets/images/trust_icon.svg';
import unverifiedIcon from '../../assets/images/unverified_icon.svg';
import trustCheck from '../../assets/images/trustCheck.svg';
import { Link } from 'react-router-dom';
import { Avatar, Box, Chip, Fab, Stack, Tooltip, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useSelector } from 'react-redux';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import utils from '../../utils/utils';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import InsightSideAiIcon from '../../assets/images/UpdatedAIIconWithCircle.svg';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

// @ts-ignore
const Navbar = ({ dashboardView, children }) => {
    const dashboard = useSelector((state: any) => state.dashboard);
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const successTooltip = () => {
        return (
            <Grid container alignItems={'center'}>
                <Grid item xs={12}>
                    <Grid container alignItems={'center'}>
                        <Grid item xs={1}>
                            <img src={trustCheck} alt="" />
                        </Grid>
                        <Grid item>
                            <Typography variant={'body2'}>Cryptographic Signature Validated</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container alignItems={'center'}>
                        <Grid item xs={1}>
                            <img src={trustCheck} alt="" />
                        </Grid>
                        <Grid item>
                            <Typography variant={'body2'}>Network Validation Verified</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container alignItems={'center'}>
                        <Grid item xs={1}>
                            <img src={trustCheck} alt="" />
                        </Grid>
                        <Grid item>
                            <Typography variant={'body2'}>Proof of Authenticity Passed</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    return (
        <div>
            <AppBar position="static" elevation={0} sx={{ backgroundColor: 'transparent !important' }}>
                <Toolbar>
                    <Grid container justifyContent={matches ? 'center' : 'space-between'} alignItems={'flex-end'}>
                        <Grid item style={{ marginTop: matches ? 20 : 10 }}>
                            <Link to={'/'} style={{ textDecoration: 'none' }}>
                                <img src={logo} alt="" height={matches ? 55 : 40} style={{ maxWidth: '100%' }} />
                            </Link>
                        </Grid>
                        {dashboardView && (
                            <Grid item>
                                <Stack direction={matches ? 'column' : 'row'} spacing={1} alignItems={'center'}>
                                    <Chip
                                        icon={<AccessTimeIcon style={{ color: '#fff' }} />}
                                        label={
                                            dashboard && dashboard.timestamp
                                                ? `Data as of ${utils.formatDate(dashboard.timestamp)}`
                                                : '---'
                                        }
                                        variant="filled"
                                        style={{ color: '#fff', fontWeight: 'bold', backgroundColor: 'transparent' }}
                                    />
                                    {dashboard && dashboard.hederaTransaction ? (
                                        <a
                                            href={`https://hederaexplorer.io/search-details/transaction/${dashboard.hederaTransaction}`}
                                            target={'_blank'}
                                            rel="noreferrer"
                                            style={{ textDecoration: 'none' }}
                                        >
                                            <Chip
                                                avatar={
                                                    <Avatar alt="Verified" src={trustIcon} style={{ padding: 1 }} />
                                                }
                                                label="Validated Proof of Authenticity"
                                                variant="filled"
                                                style={{ color: '#fff', fontWeight: 'bold', padding: 5 }}
                                                aria-owns={open ? 'mouse-over-popover' : undefined}
                                                aria-haspopup="true"
                                                onMouseEnter={handlePopoverOpen}
                                                onMouseLeave={handlePopoverClose}
                                            />
                                        </a>
                                    ) : (
                                        <Chip
                                            avatar={<Avatar alt="Unverified" src={unverifiedIcon} />}
                                            label="---"
                                            variant="filled"
                                            style={{ color: '#fff', fontWeight: 'bold' }}
                                        />
                                    )}
                                    <Tooltip title={'Acoer AI Companion'}>
                                        <Link to={'/ai-companion'} style={{ textDecoration: 'none' }}>
                                            <Fab
                                                size="small"
                                                color="inherit"
                                                aria-label="AI Companion"
                                                sx={{
                                                    backgroundColor: '#fff',
                                                }}
                                            >
                                                <img src={InsightSideAiIcon} alt="" />
                                            </Fab>
                                        </Link>
                                    </Tooltip>
                                </Stack>
                            </Grid>
                        )}
                    </Grid>
                </Toolbar>
                <Popover
                    id="mouse-over-popover"
                    sx={{
                        pointerEvents: 'none',
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                    PaperProps={{
                        style: {
                            borderRadius: 25,
                            border: 'solid #C8C8CC',
                            padding: 10,
                            maxWidth: 322,
                            marginTop: 40,
                        },
                    }}
                >
                    {successTooltip()}
                </Popover>
            </AppBar>
            {!dashboardView && (
                <Grid container alignItems={'center'} style={{ verticalAlign: 'center', minHeight: 300 }}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={10} md={5}>
                        <Grid container direction={'column'} spacing={2}>
                            <Grid item>
                                <Typography color={'#fff'} variant={'h1'} sx={{ fontWeight: 'bold', fontSize: 36 }}>
                                    Explore Real-Time
                                </Typography>
                                <Typography color={'#fff'} variant={'h1'} sx={{ fontWeight: 'bold', fontSize: 36 }}>
                                    Cancer Trial Data
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography color={'#fff'} variant={'body1'}>
                                    Providing researchers, healthcare providers, patients, and others easy access to
                                    authentic and up-to-date cancer trial information and analytics for informed
                                    decision-making.
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Link to={'/dashboard'} style={{ textDecoration: 'none', paddingRight: 10 }}>
                                    <Button
                                        variant={'contained'}
                                        style={{
                                            backgroundColor: '#fff',
                                            color: '#325599',
                                            fontWeight: 'bold',
                                            borderRadius: 30,
                                        }}
                                    >
                                        Discover Now
                                    </Button>
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <Box component="main" sx={{ p: 3 }}>
                <Grid container>{children}</Grid>
            </Box>
        </div>
    );
};

export default Navbar;
