import React, { useCallback } from 'react';

import { DashboardContext, FilterBar } from '@acoer/ac-js-lib-charts';
import filters from '../helpers/filters';
import Sticky from 'react-sticky-el';

//redux
import { bindActionCreators } from 'redux';
import * as dashboardActions from '../../DashboardActions';
import * as confirmActions from '../../../Confirmation/ConfirmationActions';
import { connect } from 'react-redux';
import { ThemeProvider } from '@mui/styles';
import { useTheme } from '@mui/material';

// @ts-ignore
const Filters = ({ actions, confirmActions, filterValues, savedFilters }) => {
    // const { dashboardId } = useContext(DashboardContext);
    const theme = useTheme();
    const dashboardId = 'clinical-trials-cancer';
    const filterDefinitions = filters(dashboardId);

    const getFilterValues = useCallback(
        (value: any, filterId: any, isTypeahead: any) => {
            actions.getFilterValues(dashboardId, value, filterId, isTypeahead, true);
        },
        [actions, dashboardId],
    );

    const handleRemoveFilter = useCallback(
        (id: any) => {
            const newFilters = { ...savedFilters };
            delete newFilters[id];
            confirmActions.showConfirmation(
                'Confirm',
                'Are you sure you want to remove this filter?',
                'removeFilter',
                newFilters,
            );
        },
        [confirmActions, savedFilters],
    );

    const handleClearFilter = useCallback(() => {
        confirmActions.showConfirmation('Confirm', 'Are you sure you want to remove this filter?', 'removeFilter', {});
    }, [confirmActions]);

    const setFilters = useCallback(
        (filters: any) => {
            actions.setFilters(filters);
        },
        [actions],
    );

    return (
        <DashboardContext.Provider
            value={{
                filters: filterDefinitions,
            }}
        >
            <ThemeProvider theme={theme}>
                <div>
                    <Sticky stickyStyle={{ zIndex: 1040 }}>
                        <FilterBar
                            filterValues={filterValues}
                            savedFilters={savedFilters}
                            getFilterValues={getFilterValues}
                            onHandleRemoveFilter={handleRemoveFilter}
                            onClearFilter={handleClearFilter}
                            setFilters={setFilters}
                            // @ts-ignore
                            mode={theme.palette.mode}
                            theme={theme}
                            filterBarType={'simple'}
                        />
                    </Sticky>
                </div>
            </ThemeProvider>
        </DashboardContext.Provider>
    );
};

function mapStateToProps(state: any) {
    return {
        filterValues: state.dashboard.filters || {},
        savedFilters: (state.dashboard.filter && state.dashboard.filter.data) || {},
        state: state,
    };
}

function mapDispatchToProps(dispatch: any) {
    return {
        // @ts-ignore
        actions: bindActionCreators(dashboardActions, dispatch),
        // @ts-ignore
        confirmActions: bindActionCreators(confirmActions, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
