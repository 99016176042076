import React, { useEffect } from 'react';
import Dashboard from '../components/Dashboard/Dashboard';
import { DashboardContext } from '@acoer/ac-js-lib-charts';
import { Grid } from '@mui/material';
import ComponentWrapper from '../containers/ComponentWrapper';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const DashboardView: React.FC = ({}) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return (
        <Grid container id={matches ? '' : 'workspace-analytics'} sx={{ mt: 8 }}>
            <DashboardContext.Provider value={{ dashboardId: 'clinical-trials-cancer' }}>
                <Dashboard />
            </DashboardContext.Provider>
        </Grid>
    );
};

export default ComponentWrapper(DashboardView);
