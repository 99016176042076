const fields = [
    {
        id: 'brief_title',
        type: 'typeahead',
        label: 'Study Title',
    },
    {
        id: 'condition',
        type: 'typeahead',
        label: 'Condition/Disease',
    },
    {
        id: 'study_type',
        type: 'list',
        label: 'Study Type',
    },
    {
        id: 'phase',
        type: 'list',
        label: 'Clinical Phase',
    },
    {
        id: 'country',
        type: 'typeahead',
        label: 'Country',
    },
    {
        id: 'Facilities.city',
        type: 'typeahead',
        label: 'City',
    },
    {
        id: 'PROCEDURE',
        type: 'typeahead',
        label: 'Treatment Procedure',
    },
    {
        id: 'DRUG',
        type: 'typeahead',
        label: 'Drug',
    },
    {
        id: 'DEVICE',
        type: 'typeahead',
        label: 'Device',
    },
    {
        id: 'Sponsor',
        type: 'typeahead',
        label: 'Sponsor',
    },
    {
        id: 'investigator',
        type: 'typeahead',
        label: 'Investigator',
    },
    {
        id: 'overall_status',
        type: 'list',
        label: 'Study Status',
    },
    {
        id: 'gender',
        type: 'list',
        label: 'Sex',
    },
    {
        id: 'start_date',
        type: 'date',
        label: 'Start Date',
    },
    {
        id: 'primary_completion_date',
        type: 'date',
        label: 'Primary Completion Date',
    },
    {
        id: 'completion_date',
        type: 'date',
        label: 'Completion Date',
    },
    {
        id: 'has_results',
        type: 'list',
        label: 'Study Results',
    },
    {
        id: 'information_types',
        type: 'list',
        label: 'Study Documents',
    },
    {
        id: 'interventions.intervention_type',
        type: 'typeahead',
        label: 'Intervention Type',
    },
    {
        id: 'keywords',
        type: 'list',
        label: 'Custom Metadata',
    },
];

export default fields;
