import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';

// redux
import { defaultStore } from './components/Shared/Redux/defaultStore';
import { Provider } from 'react-redux';

// styling
import './index.css';
import { getDesignTokens } from './styles/theme';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { PaletteMode } from '@mui/material';

// views
import DashboardView from './views/DashboardView';
import HomeView from './views/HomeView';
import CompanionView from './views/CompanionView';

export const ColorModeContext = React.createContext({
    toggleColorMode: () => {
        // This is intentional
    },
});

export const App = () => {
    // const [cookies, setCookies] = useCookies(['hde_mode']);
    const initialModeState: string | (() => string) = 'light';
    const [mode] = React.useState<PaletteMode | string>(initialModeState);
    const theme = React.useMemo(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        () => createTheme(getDesignTokens(mode)),
        [mode],
    );

    return (
        <BrowserRouter>
            <Provider store={defaultStore}>
                <ThemeProvider theme={theme}>
                    <Routes>
                        <Route exact path="/" element={<HomeView />} />
                        <Route exact path="/dashboard" element={<DashboardView />} />
                        <Route exact path="/ai-companion" element={<CompanionView />} />
                    </Routes>
                </ThemeProvider>
            </Provider>
        </BrowserRouter>
    );
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
export default App;
