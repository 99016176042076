const now = new Date();
const date = new Date(now);
const tenYearsFromDate = date.setFullYear(now.getFullYear() + 5);
const tenYearsBehindDate = date.setFullYear(now.getFullYear() - 5);

const quartersBetween = (date1: Date, date2: Date) => {
    const list = [];
    let quarter = new Date(date1.getFullYear(), Math.floor(date1.getMonth() / 3) * 3, 1);
    while (quarter <= date2) {
        list.push(quarter.getTime());
        const newQuarter = new Date(quarter.getFullYear(), quarter.getMonth(), 1);
        quarter = new Date(newQuarter.setMonth(newQuarter.getMonth() + 3));
    }
    return list;
};

export const queries = {
    reportCounts: {
        addCount: true,
        limit: 0,
        facets: [
            {
                field: 'phase',
                type: 'STRING',
            },
            {
                field: 'Sponsor',
                type: 'STRING',
                defaultBucket: 'Sponsor',
                limit: 50,
            },
            {
                field: 'start_date',
                type: 'DATE',
                boundaries: quartersBetween(new Date(tenYearsBehindDate), new Date(tenYearsFromDate)),
            },
            {
                field: 'primary_completion_date',
                type: 'DATE',
                boundaries: quartersBetween(new Date(tenYearsBehindDate), new Date(tenYearsFromDate)),
            },
            {
                field: 'completion_date',
                type: 'DATE',
                boundaries: quartersBetween(new Date(tenYearsBehindDate), new Date(tenYearsFromDate)),
            },
        ],
        useAtlasSearch: true,
    },
    facilityQuery: {
        addCount: true,
        limit: 0,
        facets: [
            {
                field: 'Facilities.city',
                type: 'STRING',
                defaultBucket: 'City',
                limit: 150,
            },
        ],
        initialFilter: {
            overall_status: {
                type: 'STRING',
                eq: 'Recruiting',
            },
        },
        useAtlasSearch: true,
    },
    recruitingCount: {
        addCount: true,
        limit: 0,
        initialFilter: {
            overall_status: {
                type: 'STRING',
                eq: 'Recruiting',
            },
        },
        useAtlasSearch: true,
    },
    trialsWithResults: {
        addCount: true,
        limit: 0,
        facets: [
            {
                field: 'has_results',
                type: 'STRING',
            },
        ],
        initialFilter: {
            has_results: {
                type: 'STRING',
                eq: 'Yes',
            },
        },
        useAtlasSearch: true,
    },
    yearTrials: {
        addCount: true,
        limit: 0,
        facets: [
            {
                field: 'start_date',
                type: 'DATE',
                boundaries: [
                    new Date(`${new Date().getFullYear() - 2}-01-01`).getTime(),
                    new Date(`${new Date().getFullYear() - 1}-01-01`).getTime(),
                ],
                defaultBucket: 'year',
            },
        ],
        initialFilter: {
            start_date: {
                type: 'TEMPORAL',
                gte: `${new Date().getFullYear()}-01-01`,
            },
        },
        useAtlasSearch: true,
    },
};

export const insightQueries = () => {
    return {
        reportCounts: {
            addCount: true,
            limit: 0,
            facets: [
                {
                    field: 'Sponsor',
                    type: 'STRING',
                    defaultBucket: 'Sponsor',
                    limit: 50,
                },
            ],
            useAtlasSearch: true,
        },
        facets: {
            addCount: true,
            facets: [
                {
                    field: 'study_type',
                    type: 'STRING',
                },
                {
                    field: 'DRUG',
                    type: 'STRING',
                },
                {
                    field: 'PROCEDURE',
                    type: 'STRING',
                    limit: 100,
                },
                {
                    field: 'DEVICE',
                    type: 'STRING',
                    limit: 100,
                },
                {
                    field: 'condition',
                    type: 'STRING',
                    limit: 50,
                },
                {
                    field: 'interventions.intervention_type',
                    type: 'STRING',
                },
                {
                    field: 'overall_status',
                    type: 'STRING',
                },
                {
                    field: 'has_results',
                    type: 'STRING',
                },
                {
                    field: 'gender',
                    type: 'STRING',
                },
                {
                    field: 'information_types',
                    type: 'STRING',
                },
            ],
            useAtlasSearch: true,
        },
        // treatmentProcedures: {
        //     addCount: true,
        //     limit: 0,
        //     facets: [
        //         {
        //             field: 'interventions.name',
        //             type: 'STRING',
        //             limit: 100,
        //         },
        //     ],
        //     initialFilter: {
        //         ['interventions.intervention_type']: {
        //             type: 'STRING',
        //             eq: 'Procedure',
        //         },
        //     },
        //     useAtlasSearch: true,
        // },
        // treatmentDevices: {
        //     addCount: true,
        //     limit: 0,
        //     facets: [
        //         {
        //             field: 'interventions.name',
        //             type: 'STRING',
        //             limit: 100,
        //         },
        //     ],
        //     initialFilter: {
        //         ['interventions.intervention_type']: {
        //             type: 'STRING',
        //             eq: 'Device',
        //         },
        //     },
        //     useAtlasSearch: true,
        // },
    };
};

export const insightDepQueries = (sponsorList: any) => {
    return {
        sponsorStatusCompleted: {
            addCount: true,
            limit: 0,
            facets: [
                {
                    field: 'Sponsor',
                    type: 'STRING',
                    limit: 50,
                },
            ],
            initialFilter: {
                overall_status: {
                    type: 'STRING',
                    eq: 'Completed',
                },
                Sponsor: {
                    type: 'STRING',
                    in: sponsorList,
                },
            },
            useAtlasSearch: true,
        },
        sponsorStatusRecruiting: {
            addCount: true,
            limit: 0,
            facets: [
                {
                    field: 'Sponsor',
                    type: 'STRING',
                    limit: 50,
                },
            ],
            initialFilter: {
                overall_status: {
                    type: 'STRING',
                    eq: 'Recruiting',
                },
                Sponsor: {
                    type: 'STRING',
                    in: sponsorList,
                },
            },
            useAtlasSearch: true,
        },
        sponsorStatusUnknown: {
            addCount: true,
            limit: 0,
            facets: [
                {
                    field: 'Sponsor',
                    type: 'STRING',
                    limit: 50,
                },
            ],
            initialFilter: {
                overall_status: {
                    type: 'STRING',
                    eq: 'Unknown status',
                },
                Sponsor: {
                    type: 'STRING',
                    in: sponsorList,
                },
            },
            useAtlasSearch: true,
        },
        sponsorStatusTerminated: {
            addCount: true,
            limit: 0,
            facets: [
                {
                    field: 'Sponsor',
                    type: 'STRING',
                    limit: 50,
                },
            ],
            initialFilter: {
                overall_status: {
                    type: 'STRING',
                    eq: 'Terminated',
                },
                Sponsor: {
                    type: 'STRING',
                    in: sponsorList,
                },
            },
            useAtlasSearch: true,
        },
        sponsorStatusActiveNotRecruiting: {
            addCount: true,
            limit: 0,
            facets: [
                {
                    field: 'Sponsor',
                    type: 'STRING',
                    limit: 50,
                },
            ],
            initialFilter: {
                overall_status: {
                    type: 'STRING',
                    eq: 'Active, not recruiting',
                },
                Sponsor: {
                    type: 'STRING',
                    in: sponsorList,
                },
            },
            useAtlasSearch: true,
        },
        sponsorStatusNotYetRecruiting: {
            addCount: true,
            limit: 0,
            facets: [
                {
                    field: 'Sponsor',
                    type: 'STRING',
                    limit: 50,
                },
            ],
            initialFilter: {
                overall_status: {
                    type: 'STRING',
                    eq: 'Not yet recruiting',
                },
                Sponsor: {
                    type: 'STRING',
                    in: sponsorList,
                },
            },
            useAtlasSearch: true,
        },
        sponsorStatusWithdrawn: {
            addCount: true,
            limit: 0,
            facets: [
                {
                    field: 'Sponsor',
                    type: 'STRING',
                    limit: 50,
                },
            ],
            initialFilter: {
                overall_status: {
                    type: 'STRING',
                    eq: 'Withdrawn',
                },
                Sponsor: {
                    type: 'STRING',
                    in: sponsorList,
                },
            },
            useAtlasSearch: true,
        },
        sponsorStatusByInvitation: {
            addCount: true,
            limit: 0,
            facets: [
                {
                    field: 'Sponsor',
                    type: 'STRING',
                    limit: 50,
                },
            ],
            initialFilter: {
                overall_status: {
                    type: 'STRING',
                    eq: 'Enrolling by invitation',
                },
                Sponsor: {
                    type: 'STRING',
                    in: sponsorList,
                },
            },
            useAtlasSearch: true,
        },
        sponsorStatusSuspended: {
            addCount: true,
            limit: 0,
            facets: [
                {
                    field: 'Sponsor',
                    type: 'STRING',
                    limit: 50,
                },
            ],
            initialFilter: {
                overall_status: {
                    type: 'STRING',
                    eq: 'Suspended',
                },
                Sponsor: {
                    type: 'STRING',
                    in: sponsorList,
                },
            },
            useAtlasSearch: true,
        },
        sponsorStatusNotAvailable: {
            addCount: true,
            limit: 0,
            facets: [
                {
                    field: 'Sponsor',
                    type: 'STRING',
                    limit: 50,
                },
            ],
            initialFilter: {
                overall_status: {
                    type: 'STRING',
                    eq: 'No longer available',
                },
                Sponsor: {
                    type: 'STRING',
                    in: sponsorList,
                },
            },
            useAtlasSearch: true,
        },
    };
};

export const listQueries = {
    reportCounts: {
        addCount: true,
        limit: 0,
        useAtlasSearch: true,
        facets: [
            {
                field: 'Sponsor',
                type: 'STRING',
                defaultBucket: 'Sponsor',
                limit: 50,
            },
        ],
    },
    tableData: {
        fields: [
            'start_date',
            'primary_completion_date',
            'completion_date',
            'brief_title',
            'nct_id',
            'overall_status',
            'phase',
            'Sponsor',
            'country',
            'condition',
            'summary',
        ],
        limit: 10,
        from: 0,
        useAtlasSearch: true,
        // sortAsc: false,
        // sortField: 'start_date',
    },
};

export const idListQueries = {
    ids: {
        limit: 0,
        useAtlasSearch: true,
        fields: ['_id'],
    },
};

export const pubmedListQueries = {
    reportCounts: {
        addCount: true,
        limit: 0,
        useAtlasSearch: true,
        dashboardId: 'pubmed-ct',
        initialFilter: {
            'externalIds.ClinicalTrials': {
                type: 'STRING',
                in: 'ids',
            },
        },
    },
    pubmedArticles: {
        fields: ['title', 'pubDate', 'journal', 'PMID', 'authors'],
        limit: 10,
        from: 0,
        useAtlasSearch: true,
        dashboardId: 'pubmed-ct',
        // sortAsc: false,
        // sortField: 'start_date',
        initialFilter: {
            'externalIds.ClinicalTrials': {
                type: 'STRING',
                in: 'ids',
            },
        },
    },
};

export const dependentQueries = {};
