// Clinical Trials
import {
    queries as trialsQueries,
    dependentQueries as trialDependentQueries,
    insightQueries,
    listQueries,
} from './dashboards/clinicaltrials/queries';
import {
    dashboardBuild as clinicalTrialsDashboardBuild,
    insightsBuild,
    listBuild,
} from './dashboards/clinicaltrials/build';

export const dashboardBuild = (type: string, setFilters: any, dashboard: any) => {
    if (type === 'clinical-trials-cancer') {
        return {
            tabs: [
                {
                    label: 'Overview',
                    description: '',
                    builder: clinicalTrialsDashboardBuild(setFilters, dashboard),
                    queries: trialsQueries,
                    dependentQueries: trialDependentQueries,
                },
                {
                    label: 'Insights',
                    description: '',
                    builder: insightsBuild(setFilters, dashboard),
                    queries: insightQueries(),
                },
                {
                    label: 'Trials List',
                    description: '',
                    builder: listBuild,
                    queries: listQueries,
                },
            ],
        };
    }
};
