import React, { useState, useCallback, useEffect, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';

// component and helper imports
import FreeTextSearch from './FreeTextSearch';
import { dashboardBuild } from './Analytics/helpers/dashboardBuild';
import { getDate } from './Analytics/helpers/utils';
import { Dashboard, DashboardContext } from '@acoer/ac-js-lib-charts';
import Filters from './Analytics/Filters/Filters';
import TrialDetailDialog from './TrialDetailDialog';

// material- ui
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// redux additions
import { dashboardActions } from './DashboardActions';
import { connect } from 'react-redux';
import { IDashboardAction, IDashboardCompProps, IDashboardProps } from './DashboardModels';
import { bindActionCreators, Dispatch } from 'redux';
import Alert from '@mui/material/Alert';
import { insightDepQueries } from './Analytics/helpers/dashboards/clinicaltrials/queries';

const operatorMap = {
    is: 'eq',
    'is not': 'ne',
    contains: 'like',
    'greater than or equal to': 'gte',
    'lower than or equal to': 'lte',
    'is one of': 'in',
    'is not one of': 'nin',
};

const getWhere = (initial: any, where: any) =>
    (where &&
        Object.keys(where).reduce((accum, current) => {
            if (Object.keys(accum).includes(current) && where[current].type !== 'date') {
                accum[current] = {
                    ...accum[current],
                    [operatorMap[where[current].operator] || where[current].operator]: where[current].values,
                };
                return accum;
            }

            if (where[current].type === 'date') {
                if (Array.isArray(where[current].values)) {
                    accum[current] = {
                        type: 'TEMPORAL',
                        gte: where[current].values[0],
                        lte: where[current].values[1],
                    };
                } else {
                    accum[current] = {
                        type: 'TEMPORAL',
                        gte: getDate(where[current].values),
                    };
                }
            } else if (where[current].type === 'NUMERICAL') {
                const isArrayOperator = Array.isArray(where[current].operator);
                if (isArrayOperator) {
                    accum[current] = where[current].operator.reduce(
                        (operatorAccum: any, op: string, idx: number) => ({
                            ...operatorAccum,
                            [op]: parseInt(where[current].values[idx]),
                        }),
                        {
                            type: 'NUMERICAL',
                        },
                    );
                } else {
                    accum[current] = {
                        type: 'NUMERICAL',
                        [operatorMap[where[current].operator] || where[current].operator]: parseInt(
                            where[current].values,
                        ),
                    };
                }
            } else {
                accum[current] = {
                    type: where[current].type || 'STRING',
                    [operatorMap[where[current].operator] || where[current].operator]: where[current].values,
                };
            }

            return accum;
        }, initial || {})) ||
    initial;

const DashboardComp: React.FC<IDashboardCompProps> = ({ actions }) => {
    // @ts-ignore
    const { dashboardId } = useContext(DashboardContext);
    const [tabValue, setTabValue] = useState(0);
    const [dependentData, setDependentData] = useState({});
    const [freeTextSearch, setFreeTextSearch] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedTrial, setSelectedTrial] = useState(null);
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));
    const dashboard = useSelector((state: any) => state.dashboard);
    const savedFilters = useSelector((state: any) => state.dashboard.filter && state.dashboard.filter.data);

    const handleFreeTextSearchChange = (event: any) => {
        const value = event.target.value;
        if (value === '') {
            retrieveData(savedFilters || null, '');
            setFreeTextSearch('');
        } else {
            setFreeTextSearch(event.target.value);
        }
    };

    const handleQuickSearch = () => {
        retrieveData(savedFilters || null, freeTextSearch);
    };

    const handleFetchPubMedData = (data: any, type: string) => {
        actions?.getDashboardData(
            'pubmed-ct',
            type,
            ['title', 'pubDate', 'journal', 'PMID', 'authors'],
            false,
            undefined,
            100,
            undefined,
            undefined,
            { ['externalIds.ClinicalTrials']: { type: 'STRING', in: data } },
            0,
            undefined,
            undefined,
        );
    };

    const dependentQueries = useMemo(
        () => ({
            latestRecordedDate: () => ({
                topMeans:
                    dashboard && dashboard.lastRecordedDate && dashboard.lastRecordedDate.items.length > 0
                        ? dashboard?.lastRecordedDate.items[0].date
                        : '2021-02-07',
            }),
            vaccinationPercent: () => ({
                latestRecordedDate:
                    dashboard && dashboard.lastRecordedDate && dashboard.lastRecordedDate.items.length > 0
                        ? dashboard?.lastRecordedDate?.items[0].date
                        : '2021-02-07',
            }),
        }),
        [dashboard],
    );

    const retrieveData = useCallback(
        (filter: { data: any }, freeTextSearch: any) => {
            let queryProp = {};
            const queries = {
                ...dashboardBuild(dashboardId, addFilter, dashboard)?.tabs[tabValue].queries,
            };
            Object.keys(queries).forEach((query) => {
                const initialValue = queries[query]['initialFilter'];
                queryProp = {
                    ...queryProp,
                    [query]: {
                        ...queries[query],
                        where: getWhere({ ...initialValue }, filter),
                        ...(freeTextSearch && {
                            search: freeTextSearch,
                        }),
                    },
                };
            });

            const depQuery = dashboardBuild(dashboardId, addFilter, dashboard)?.tabs[tabValue].dependentQueries || null;
            actions?.getDashboardDataMultiple(dashboardId, queryProp).then((res: any) => {
                // GET PUBMED DATA WITH FETCHED IDS FOR TABLE
                if (res && res.tableData) {
                    const data = res.tableData.items.map((item: any) => item.nct_id);
                    data.map((item: any) => {
                        handleFetchPubMedData([item], 'trialListPubs');
                    });
                } else if (
                    res &&
                    res.reportCounts &&
                    res.reportCounts.items &&
                    res.reportCounts.items.length > 0 &&
                    res.reportCounts.items[0].facet &&
                    res.reportCounts.items[0].facet.SponsorFacet &&
                    res.reportCounts.items[0].facet.SponsorFacet.buckets
                ) {
                    let sponsorList = [];
                    sponsorList = res.reportCounts.items[0].facet.SponsorFacet.buckets.map(function (el: {
                        _id: string;
                    }) {
                        return el._id;
                    });
                    const queries = insightDepQueries(sponsorList);
                    Object.keys(queries).forEach((query) => {
                        const initialValue = queries[query]['initialFilter'];
                        queryProp = {
                            ...queryProp,
                            [query]: {
                                ...queries[query],
                                where: getWhere({ ...initialValue }, filter),
                                ...(freeTextSearch && {
                                    search: freeTextSearch,
                                }),
                            },
                        };
                    });
                    actions?.getDashboardDataMultiple(dashboardId, queryProp);
                }

                // RESUME ORIGINAL DEP QUERY FETCH
                if (res && res.ids) {
                    const depData: IDashboardProps = Object.keys(res).reduce((accum, key) => {
                        if (queries[key].groupBy) {
                            return {
                                ...accum,
                                [key]:
                                    res[key].items.length > 0
                                        ? res[key].items.map((value: any) => value[queries[key].groupBy[0]])
                                        : ['none'],
                            };
                        } else if (queries[key].fields) {
                            if (queries[key].fields.length > 1) {
                                return {
                                    ...accum,
                                    [key]:
                                        res[key].items.length > 0
                                            ? res[key].items.map((value: any) => value[queries[key]])
                                            : ['none'],
                                };
                            } else {
                                return {
                                    ...accum,
                                    [key]:
                                        res[key].items.length > 0
                                            ? res[key].items.map((value: any) => value[queries[key].fields[0]])
                                            : ['none'],
                                };
                            }
                        }
                        return accum;
                    }, {});

                    // depData.latestRecordedDate =
                    //     res.lastRecordedDate.items.length > 0 ? res.lastRecordedDate.items[0].date : '2021-02-07';

                    setDependentData(depData);

                    let completeDepQuery = {};
                    const depQueries = { ...depQuery };
                    const dashboardValue = Object.keys(depQueries).reduce(
                        (accum, key) => depQueries[key].dashboardId || dashboardId,
                        dashboardId,
                    );

                    Object.keys(depQueries).forEach((query) => {
                        const initialValue = depQueries[query]['initialFilter'];
                        let initialCopy = { ...initialValue };
                        const filterKeys = Object.keys(initialCopy);

                        // loop through dependency filters to apply arrays to initial filter
                        for (let i = 0; i < filterKeys.length; i++) {
                            let nestedCopy = initialCopy[Object.keys(initialCopy)[i]];
                            const key = filterKeys[i];

                            nestedCopy = {
                                ...nestedCopy,
                                eq: depData[initialValue[key]['eq']],
                                in: depData[initialValue[key]['in']],
                            };
                            initialCopy = {
                                ...initialCopy,
                                [key]: nestedCopy,
                            };
                        }

                        completeDepQuery = {
                            ...completeDepQuery,
                            [query]: {
                                ...depQueries[query],
                                where: getWhere({ ...initialCopy }, dashboardValue !== dashboardId ? {} : filter),
                                search: dashboardValue !== dashboardId ? '' : freeTextSearch,
                            },
                        };
                    });

                    actions.getDashboardDataMultiple(dashboardValue, completeDepQuery);
                }
            });
        },
        [actions, dashboardId, tabValue],
    );

    const onDownloadCsv = useCallback(
        (dataType: string | number, queries: { [x: string]: any }, depQueries: { [x: string]: any }) => {
            if (depQueries) {
                const dashboardValue = Object.keys(depQueries).reduce(
                    (accum, key) => depQueries[key].dashboardId || dashboardId,
                    dashboardId,
                );
                const initialValue = depQueries[dataType]['initialFilter'];
                let initialCopy = { ...initialValue };
                const filterKeys = Object.keys(initialCopy);

                // loop through dependency filters to apply arrays to initial filter
                for (let i = 0; i < filterKeys.length; i++) {
                    let nestedCopy = initialCopy[Object.keys(initialCopy)[i]];
                    const key = filterKeys[i];

                    nestedCopy = {
                        ...nestedCopy,
                        in: dependentData[initialValue[key]['in']],
                        eq: dependentData[initialValue[key]['eq']],
                    };
                    initialCopy = {
                        ...initialCopy,
                        [key]: nestedCopy,
                    };
                }
                const filteredDepQuery = {
                    ...depQueries[dataType],
                    where: getWhere(null, { ...savedFilters }),
                    search: dashboardValue !== dashboardId ? '' : freeTextSearch,
                };
                actions?.exportDashboardData(dashboardId, dataType, filteredDepQuery);
            } else {
                const initialValue = queries[dataType]['initialFilter'];
                const filteredQuery = {
                    ...queries[dataType],
                    where: getWhere({ ...initialValue }, { ...savedFilters }),
                    search: freeTextSearch,
                };
                actions?.exportDashboardData(dashboardId, dataType, filteredQuery);
            }
        },
        [actions, dashboardId, dependentData, savedFilters],
    );

    const setFilters = useCallback(
        (filters: any) => {
            Object.keys(filters).map((value) => {
                const updatedFilter = {
                    id: value,
                    operator: operatorMap[filters[value].operator] || filters[value].operator,
                    values: filters[value].values,
                };
                return (filters[value] = updatedFilter);
            });
            actions?.addFilter(filters);
        },
        [actions],
    );

    const addFilter = (filters: any) => {
        actions?.addFilter(filters);
    };

    const handleTabChange = useCallback(
        (newTab: any) => {
            setTabValue(newTab);
        },
        [setTabValue],
    );

    const handleTableData = useCallback(
        (
            id: any,
            fields: any,
            addCount: any,
            groupBy: any,
            limit: any,
            sortAsc: any,
            sortField: any,
            where: any,
            startFrom: number,
        ) => {
            const dashboardValue =
                // @ts-ignore
                dashboardBuild(dashboardId, addFilter, dashboard)?.tabs[tabValue].dashboardId || dashboardId;
            // @ts-ignore
            const dataKey = dashboardBuild(dashboardId, addFilter, dashboard)?.tabs[tabValue].tableData || 'tableData';
            const depQuery: any =
                dashboardBuild(dashboardId, addFilter, dashboard)?.tabs[tabValue].dependentQueries || null;
            const fieldsList = depQuery && depQuery[dataKey] ? depQuery[dataKey].fields : fields;
            const whereClause = savedFilters ? getWhere({ ...where }, { ...savedFilters }) : where;

            if (dashboard?.tableFilter) {
                if (dashboard.tableFilter.sortField !== sortField || dashboard.tableFilter.sortAsc !== sortAsc) {
                    actions
                        ?.getDashboardData(
                            dashboardValue,
                            id,
                            fieldsList,
                            addCount,
                            groupBy,
                            limit,
                            sortAsc,
                            sortField,
                            whereClause,
                            0,
                            undefined,
                            undefined,
                            dashboardValue !== dashboardId ? '' : freeTextSearch,
                        )
                        .then((res: any) => {
                            const data = res.items.map((item: any) => item.nct_id);
                            data.map((item: any) => {
                                handleFetchPubMedData([item], 'trialListPubs');
                            });
                        });
                } else if (dashboard.tableFilter.from !== startFrom) {
                    actions
                        ?.getDashboardData(
                            dashboardValue,
                            id,
                            fieldsList,
                            addCount,
                            groupBy,
                            limit,
                            sortAsc,
                            sortField,
                            whereClause,
                            startFrom,
                            (startFrom || 0) > dashboard.tableFilter.from
                                ? dashboard[dataKey]?.items[dashboard[dataKey]?.items.length - 1].paginationToken
                                : undefined,
                            (startFrom || 0) < dashboard.tableFilter.from
                                ? dashboard[dataKey]?.items[0].paginationToken
                                : undefined,
                            dashboardValue !== dashboardId ? '' : freeTextSearch,
                        )
                        .then((res: any) => {
                            const data = res.items.map((item: any) => item.nct_id);
                            data.map((item: any) => {
                                handleFetchPubMedData([item], 'trialListPubs');
                            });
                        });
                } else {
                    actions
                        ?.getDashboardData(
                            dashboardValue,
                            id,
                            fieldsList,
                            addCount,
                            groupBy,
                            limit,
                            sortAsc,
                            sortField,
                            whereClause,
                            startFrom,
                            dashboard.tableFilter.from,
                            undefined,
                            dashboardValue !== dashboardId ? '' : freeTextSearch,
                        )
                        .then((res: any) => {
                            const data = res && res.items && res.items.map((item: any) => item.nct_id);
                            data.map((item: any) => {
                                handleFetchPubMedData([item], 'trialListPubs');
                            });
                        });
                }
            } else {
                actions
                    ?.getDashboardData(
                        dashboardValue,
                        id,
                        fieldsList,
                        addCount,
                        groupBy,
                        limit,
                        sortAsc,
                        sortField,
                        whereClause,
                        startFrom,
                        startFrom > 0
                            ? dashboard[dataKey]?.items[dashboard[dataKey]?.items.length - 1].paginationToken
                            : undefined,
                        undefined,
                        dashboardValue !== dashboardId ? '' : freeTextSearch,
                    )
                    .then((res: any) => {
                        const data = res.items.map((item: any) => item.nct_id);
                        data.map((item: any) => {
                            handleFetchPubMedData([item], 'trialListPubs');
                        });
                    });
            }
        },
        [actions, dashboardId, dashboard, tabValue, dependentData],
    );

    const onTableRowClick = useCallback(
        (data: any) => {
            if (data.length === 4) {
                window.open(`https://pubmed.ncbi.nlm.nih.gov/${data[1]}`, '_blank');
            } else {
                setOpenDialog(true);
                setSelectedTrial(data);
                handleFetchPubMedData([data[1]], 'selectedTrialPubMed');
            }
        },
        [dashboardId],
    );

    useEffect(() => {
        retrieveData(savedFilters || null, freeTextSearch);
    }, [actions, retrieveData, savedFilters]);

    useEffect(() => {
        actions?.getTimestamp();
        return () => actions?.reset();
    }, [actions, dashboardId]);

    // @ts-ignore
    return (
        <div>
            <Grid container justifyContent={matches ? 'center' : 'space-between'} spacing={4}>
                {matches && (
                    <Grid item xs={11} style={{ textAlign: 'center', marginTop: -50 }}>
                        <Alert severity="warning">
                            The Cancer Trials Explorer is developed for an optimum experience on a desktop view. Some
                            capabilites are limited on mobile devices.
                        </Alert>
                    </Grid>
                )}
            </Grid>
            <Grid container justifyContent="center" style={{ marginTop: matches ? 50 : 0 }}>
                <Grid item xs={12}>
                    <Grid container spacing={1} justifyContent="space-between">
                        <Grid item xs={12}>
                            <Grid container alignItems="center">
                                <Dashboard
                                    dashboardData={dashboard || []}
                                    dashboardBuild={dashboardBuild(dashboardId, addFilter, dashboard)}
                                    dependentQueries={dependentQueries}
                                    savedFilters={savedFilters && savedFilters.data}
                                    tabChange={handleTabChange}
                                    exportData={onDownloadCsv}
                                    // @ts-ignore
                                    retrieveDashboardData={handleTableData}
                                    setFilters={setFilters}
                                    onTableRowClick={onTableRowClick}
                                    tabValue={tabValue}
                                    theme={theme}
                                    customComponent={
                                        <Grid
                                            container
                                            justifyContent={'flex-end'}
                                            spacing={2}
                                            alignItems={'flex-start'}
                                        >
                                            <Grid
                                                item
                                                style={{
                                                    marginTop: 4,
                                                }}
                                            >
                                                <Filters />
                                            </Grid>
                                            {!matches && (
                                                <Grid item xs={10} lg={7} xl={7}>
                                                    <FreeTextSearch
                                                        onHandleChange={handleFreeTextSearchChange}
                                                        onHandleQuickSearch={handleQuickSearch}
                                                        value={freeTextSearch}
                                                    />
                                                </Grid>
                                            )}
                                        </Grid>
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <TrialDetailDialog
                open={openDialog}
                setOpen={setOpenDialog}
                selectedTrial={selectedTrial}
                setSelectedTrial={setSelectedTrial}
            />
        </div>
    );
};

const mapDispatchToProps = (dispatch: Dispatch<IDashboardAction>) => ({
    actions: bindActionCreators(dashboardActions, dispatch),
});

export default connect(null, mapDispatchToProps)(DashboardComp);
