import React from 'react';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';

// components and helpers
import hederaImage from '../assets/images/hedera-hashgraph-hbar-logo.png';
import acoerLogo from '../assets/images/acoerLogo.png';
import InsightSideAiIcon from '../assets/images/UpdatedAIIconWithCircle.svg';
import videoImage from '../assets/images/video_image.png';
import ComponentWrapper from '../containers/ComponentWrapper';

// mui
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { List, ListItem, ListItemAvatar, ListItemText, Paper } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const HomeView: React.FC = ({}) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    const bulletPoints = [
        'Cancer Trials Explorer is a blockchain-enabled, user-friendly platform offering real-time data visualization and streamlined search for cancer studies aggregated from ClinicalTrials.gov.',
        'Providing researchers, healthcare providers, patients, and anyone seeking comprehensive cancer trial data for informed decision-making.',
        'Platform offers analytics for informed decision-making, supporting a wide range of stakeholders.',
    ];
    return (
        <div
            style={{
                backgroundRepeat: 'no-repeat',
                minHeight: '100%',
                marginTop: 50,
            }}
        >
            <Grid container justifyContent={'center'} alignItems={'center'} spacing={3}>
                <Grid item xs={12} md={11}>
                    <Paper elevation={0} style={{ padding: 40, borderRadius: 15, paddingBottom: matches ? 400 : 0 }}>
                        <Grid container spacing={4} alignItems={'center'}>
                            <Grid item xs={12} md={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant={'h4'} style={{ fontWeight: 'bold' }} color={'primary'}>
                                            Cancer Trials Explorer
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>
                                            Acoer's Cancer Trial Explorer is a blockchain-enabled platform that provides
                                            real-time data visualization and streamlined search for cancer studies from
                                            ClinicalTrials.gov, offering easy access to reliable, up-to-date information
                                            for informed decision-making.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <List>
                                            {bulletPoints.map((value: string, index: number) => {
                                                return (
                                                    <ListItem key={`homepage_bullet_${index}`}>
                                                        <ListItemAvatar>
                                                            <CheckCircleIcon color={'primary'} />
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            primary={
                                                                <Typography variant={'subtitle2'}>{value}</Typography>
                                                            }
                                                        />
                                                    </ListItem>
                                                );
                                            })}
                                        </List>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>
                                            Explore the dashboard or contact Acoer for more information on accessing
                                            comprehensive cancer trial data.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} style={{ marginBottom: matches ? 50 : 40 }}>
                                        <Link to={'/dashboard'} style={{ textDecoration: 'none', paddingRight: 10 }}>
                                            <Button
                                                variant={'contained'}
                                                color={'primary'}
                                                style={{
                                                    fontWeight: 'bold',
                                                    borderRadius: 30,
                                                }}
                                            >
                                                View Dashboard
                                            </Button>
                                        </Link>
                                        <a
                                            href="https://www.acoer.com/company/contact"
                                            target={'_blank'}
                                            rel="noreferrer"
                                            style={{ textDecoration: 'none' }}
                                        >
                                            <Button
                                                variant={'outlined'}
                                                color={'primary'}
                                                style={{
                                                    fontWeight: 'bold',
                                                    borderRadius: 30,
                                                    marginLeft: 5,
                                                }}
                                            >
                                                Contact Us
                                            </Button>
                                        </a>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6} style={{ position: 'relative' }}>
                                <ReactPlayer
                                    url={'https://vimeo.com/960684509'}
                                    light={videoImage}
                                    controls={true}
                                    style={{
                                        borderRadius: '50%',
                                        display: 'block',
                                        margin: 'auto',
                                        maxWidth: '100%',
                                        position: matches ? 'absolute' : 'relative',
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={11}>
                    <Paper elevation={0} style={{ padding: 40, borderRadius: 15 }}>
                        <Grid container justifyContent={'center'} direction={'column'} alignItems={'center'}>
                            <Grid container justifyContent={'center'}>
                                <Grid item xs={12}>
                                    <Grid container alignItems={'center'} spacing={3}>
                                        <Grid item>
                                            <img
                                                src={InsightSideAiIcon}
                                                alt="Hedera Hashgraph"
                                                style={{ width: 75, borderRadius: '0%' }}
                                            />
                                        </Grid>
                                        <Grid item md={10} xs={12}>
                                            <Typography variant={'body1'}>
                                                Discover how <Link to={'/ai-companion'}>Acoer’s AI Companion</Link> for
                                                Cancer Trials is transforming the search for cancer clinical trials with
                                                a focus on integrity and user empowerment.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={8} xs={11} style={{ marginTop: 30 }}>
                                <Typography variant={'body1'}>
                                    This AI tool revolutionizes how you find relevant studies from ClinicalTrials.gov
                                    using advanced algorithms to deliver the best information available. The natural
                                    language processing feature allows you to interact with the AI just like you’re
                                    having a conversation, making your search for trials intuitive and stress-free.
                                    Plus, its user-friendly interface means you can access critical information in no
                                    time.
                                </Typography>
                            </Grid>
                            <Grid item md={8} xs={11} style={{ marginTop: 30 }}>
                                <Typography variant={'body1'}>
                                    What sets this tool apart is its commitment to transparency and accountability
                                    through ethical AI. By leveraging Hedera data stamping and lineage tracking, every
                                    AI-driven decision is securely recorded, ensuring that the entire process is
                                    transparent and trustworthy. Experience the power of ethical AI with Acoer’s AI
                                    Companion for Cancer Trials!
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={11}>
                    <Paper elevation={0} style={{ padding: 40, borderRadius: 15 }}>
                        <Grid container justifyContent={'center'} direction={'column'} alignItems={'center'}>
                            <Grid container justifyContent={'center'}>
                                <Grid item xs={12}>
                                    <Grid container alignItems={'center'} spacing={3}>
                                        <Grid item>
                                            <img
                                                src={hederaImage}
                                                alt="Hedera Hashgraph"
                                                style={{ width: 75, borderRadius: '0%' }}
                                            />
                                        </Grid>
                                        <Grid item md={10} xs={12}>
                                            <Typography variant={'body1'}>
                                                Acoer uses{' '}
                                                <a
                                                    href="https://hedera.com/how-it-works"
                                                    target={'_blank'}
                                                    rel="noreferrer"
                                                >
                                                    Hedera
                                                </a>{' '}
                                                to publicly verify data changes. Each time Cancer Trials data changes,
                                                we retrieve the latest dataset and simultaneously{' '}
                                                <a href="https://stamp.hashlog.io/" target={'_blank'} rel="noreferrer">
                                                    Data Stamp
                                                </a>{' '}
                                                it to transparently prove the most current state of the data.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={8} xs={11} style={{ marginTop: 30 }}>
                                <Typography variant={'body1'}>
                                    All cancer trials data are consumed and stored in our data lake while also
                                    leveraging the power and security of{' '}
                                    <a href="https://hedera.com/" target={'_blank'} rel="noreferrer">
                                        Hedera Hashgraph
                                    </a>
                                    , a Distributed Ledger Technology (DLT), in order to provide a layer of confidence
                                    for the user viewing the trials. A timestamped hash of each clinical trial dataset
                                    stored on Hedera Hashgraph to ensure data provenance which proves what we are
                                    showing on the dashboard is exactly what is provided by the U.S. National Library of
                                    Medicine, allows us to audit all information captured while having the ability to
                                    prove where it originated, who authored it, as well as if it has been modified
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justifyContent={'center'} alignItems={'center'} spacing={3} direction={'column'}>
                        <Grid item>
                            <a
                                href="https://www.acoer.com/"
                                target={'_blank'}
                                rel="noreferrer"
                                style={{ textDecoration: 'none' }}
                            >
                                <img src={acoerLogo} alt="Acoer" height={50} />
                            </a>
                        </Grid>
                        <Grid item>
                            <Typography variant={'body1'}>Copyright © 2024 | All Rights Reserved</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default ComponentWrapper(HomeView);
