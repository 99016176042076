import moment from 'moment/moment';
import axios from 'axios';

//Set authorization token automatically for API calls
const utils = {
    formatDate: (date, format = 'L HH:mm') => {
        return moment(date).format(format);
    },
    setAuthorizationToken: (token) => {
        if (token) {
            axios.defaults.headers.common.Authorization = `Bearer ${token}`;
        } else {
            delete axios.defaults.headers.common.Authorization;
        }
    },
    toTitleText: (text) => {
        const result = text.replace(/([A-Z])/g, ' $1');
        return result.charAt(0).toUpperCase() + result.slice(1);
    },
    splitCamelcaseString: (string) => string.replace(/([a-z0-9])([A-Z])/g, '$1 $2'),
};

export default utils;
