import { ActionCreatorsMapObject, Dispatch, ActionCreator, Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import axios from 'axios';
import FileDownload from 'js-file-download';
import {
    DASHBOARD_DATA_REQUEST,
    DASHBOARD_DATA_SUCCESS,
    DASHBOARD_SINGLE_DATA_REQUEST,
    DASHBOARD_SINGLE_DATA_SUCCESS,
    DASHBOARD_DATA_FAILURE,
    DASHBOARD_SET_FILTER,
    DASHBOARD_RESET,
    DASHBOARD_FILTER_REQUEST,
    DASHBOARD_FILTER_SUCCESS,
    DASHBOARD_FILTER_FAILURE,
    DASHBOARD_DOWNLOAD_REQUEST,
    DASHBOARD_DOWNLOAD_SUCCESS,
    DASHBOARD_ADD_FILTER,
    DASHBOARD_EXPORT_REQUEST,
    DASHBOARD_EXPORT_SUCCESS,
    DASHBOARD_EXPORT_FAILURE,
    DASHBOARD_DATA_TIMESTAMP_REQUEST,
    DASHBOARD_DATA_TIMESTAMP_SUCCSS,
    DASHBOARD_DATA_TIMESTAMP_FAILURE,
    DASHBOARD_PUBMED_DATA_SUCCESS,
    COORDS_DATA_REQUEST,
    COORDS_DATA_SUCCESS,
    COORDS_DATA_FAILURE,
    DASHBOARD_SELECTED_PUBMED_DATA_SUCCESS,
} from './DashboardReducer';
import { IDashboardAction, IDashboardState } from './DashboardModels';

const API_EXPORT_DASHBOARD = process.env.REACT_APP_API_DASHBOARD_EXPORT;
const API_ROOT = process.env.REACT_APP_API_ROOT;

export const requestFileDownload: (id: string) => { id: string; type: string } = (id: string) => ({
    type: DASHBOARD_DOWNLOAD_REQUEST,
    id,
});

export const successFileDownload: (id: string, name: string, payload: any) => { id: string; type: string } = (
    id: string,
    name: string,
    payload: any,
) => {
    FileDownload(payload, name);
    return {
        type: DASHBOARD_DOWNLOAD_SUCCESS,
        id,
    };
};

//= ========Thunks===========

export const getDashboardData: ActionCreator<ThunkAction<Promise<Action>, IDashboardState, null, IDashboardAction>> =
    (
        dashboardId: string,
        dataType: string,
        fields: any,
        addCount: boolean,
        groupBy: string,
        limit: number,
        sortAsc: string,
        sortField: string,
        where: any,
        startFrom: number,
        searchAfter: string,
        searchBefore: string,
        text: string,
    ) =>
    async (dispatch: Dispatch) => {
        dispatch({ type: DASHBOARD_SINGLE_DATA_REQUEST, dataType });
        return await axios({
            method: 'POST',
            url: `${API_ROOT}/${dashboardId}/query`,
            data: {
                addCount,
                groupBy,
                fields,
                limit,
                sortAsc,
                sortField,
                where,
                search: text,
                from: 0,
                searchAfter,
                searchBefore,
                useAtlasSearch: true,
            },
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                Accept: 'application/json',
            },
        })
            .then((response) => {
                if (dataType === 'trialListPubs') {
                    const nctId = where['externalIds.ClinicalTrials'].in[0];
                    dispatch({
                        type: DASHBOARD_PUBMED_DATA_SUCCESS,
                        payload: response.data,
                        nctId,
                        dataType,
                    });
                } else if (dataType === 'selectedTrialPubMed') {
                    dispatch({
                        type: DASHBOARD_SELECTED_PUBMED_DATA_SUCCESS,
                        payload: response.data,
                        dataType,
                    });
                } else {
                    dispatch({
                        type: DASHBOARD_SINGLE_DATA_SUCCESS,
                        payload: response.data,
                        dataType,
                        fips: dashboardId,
                        tableFilter: {
                            ...((searchBefore || searchAfter) && {
                                addCount,
                                groupBy,
                                fields,
                                limit,
                                sortAsc,
                                sortField,
                                where,
                                search: text,
                                from: startFrom,
                                searchAfter,
                                searchBefore,
                            }),
                        },
                    });
                }
                return response.data;
            })
            .catch((error) => {
                dispatch({ type: DASHBOARD_DATA_FAILURE, error });
            });
    };

export const getTimestamp: ActionCreator<ThunkAction<Promise<Action>, IDashboardState, null, IDashboardAction>> =
    () => async (dispatch: Dispatch) => {
        dispatch({ type: DASHBOARD_DATA_TIMESTAMP_REQUEST });

        return await axios({
            method: 'POST',
            url: `${API_ROOT}/clinical-trials-fed-sync-jobs/query`,
            data: {
                addCount: false,
                fields: ['datetime', 'hederaTransactionId', 'hederaTopicId'],
                limit: 1,
                sortAsc: false,
                sortField: 'datetime',
                from: 0,
                where: {
                    outcome: {
                        type: 'STRING',
                        eq: 'SUCCESS',
                    },
                },
            },
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                Accept: 'application/json',
            },
        })
            .then((response) => {
                if (response.data.items && response.data.items.length > 0) {
                    return dispatch({
                        type: DASHBOARD_DATA_TIMESTAMP_SUCCSS,
                        timestamp: response.data.items[0].datetime,
                        topic: response.data.items[0].hederaTopicId,
                        transaction: response.data.items[0].hederaTransactionId,
                    });
                } else {
                    return dispatch({ type: DASHBOARD_DATA_TIMESTAMP_FAILURE });
                }
            })
            .catch((error) => {
                return dispatch({ type: DASHBOARD_DATA_TIMESTAMP_FAILURE });
            });
    };

export const getDashboardDataMultiple: ActionCreator<
    ThunkAction<Promise<Action>, IDashboardState, null, IDashboardAction>
> = (dashboardId: string, queries: any) => async (dispatch: Dispatch) => {
    dispatch({ type: DASHBOARD_DATA_REQUEST });

    return await axios({
        method: 'POST',
        url: `${API_ROOT}/${dashboardId}/query/multiple`,
        data: queries,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
        },
    })
        .then((response) => {
            dispatch({
                type: DASHBOARD_DATA_SUCCESS,
                payload: response.data,
                fips: dashboardId,
            });
            return response.data;
        })
        .catch((error) => {
            dispatch({ type: DASHBOARD_DATA_FAILURE, error });
        });
};

export const getFilterValues: (
    dashboardId: string,
    id: string,
    filterId: string,
    typeahead: string[],
    needUnwind: boolean,
) => (dispatch: Dispatch, getState: any) => void = (
    dashboardId: string,
    id: string,
    filterId: string,
    typeahead: string[],
    needUnwind: boolean,
) => {
    const thunk = async (dispatch: Dispatch, getState: any) => {
        dispatch({ type: DASHBOARD_FILTER_REQUEST, filterId: typeahead ? filterId : id });
        let data;
        if (typeahead) {
            data = {
                search: id,
                searchField: filterId,
                autocomplete: true,
                limit: 20,
                facets: [
                    {
                        field: filterId,
                        type: 'STRING',
                    },
                ],
                // sortAsc: true,
                // sortField: filterId,
                // unwind: needUnwind ? [filterId] : undefined,
                // where: {
                //     [filterId]: {
                //         type: 'STRING',
                //         like: id,
                //     },
                // },
            };
        } else {
            data = {
                facets: [
                    {
                        field: id,
                        type: 'STRING',
                    },
                ],
                where: {
                    [id]: {
                        type: 'STRING',
                        notNull: true,
                    },
                },
            };
        }

        return await axios({
            method: 'POST',
            url: `${API_ROOT}/${dashboardId}/query`,
            data: data,
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                Accept: 'application/json',
            },
        })
            .then((response) => {
                dispatch({
                    type: DASHBOARD_FILTER_SUCCESS,
                    payload: response.data,
                    filterId: typeahead ? filterId : id,
                });
                return response.data;
            })
            .catch((error) => {
                dispatch({ type: DASHBOARD_FILTER_FAILURE, error });
            });
    };

    thunk.meta = {
        debounce: {
            time: 500,
            key: 'filter-values-lookup',
        },
    };
    return thunk;
};

export const setFilters = (filters: any) => async (dispatch: Dispatch) => {
    return dispatch({ type: DASHBOARD_SET_FILTER, payload: filters });
};

export const addFilter = (filters: any) => async (dispatch: Dispatch) => {
    return dispatch({ type: DASHBOARD_ADD_FILTER, payload: filters });
};

export const reset = () => async (dispatch: Dispatch) => {
    return dispatch({ type: DASHBOARD_RESET });
};

export const exportDashboardData = (queryType: string, dataType: string, query: any) => async (dispatch: Dispatch) => {
    dispatch(requestFileDownload(dataType));

    return await axios({
        method: 'POST',
        url: `${API_ROOT}/${queryType}/export`,
        data: query,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'text/plain',
        },
        responseType: 'blob',
    })
        .then((response) => {
            dispatch(successFileDownload(dataType, dataType + '.csv', response.data));
            return response.data;
        })
        .catch((error) => {
            dispatch({ type: DASHBOARD_DATA_FAILURE, error });
        });
};

export const exportDashboard = (type: string, build: any, filters: any, text: string) => (dispatch: Dispatch) => {
    dispatch({
        type: DASHBOARD_EXPORT_REQUEST,
    });

    return axios({
        method: 'POST',
        url: API_EXPORT_DASHBOARD,
        data: {
            token: '',
            filters,
            text,
            build: JSON.stringify(build, (_key, val) => {
                if (typeof val === 'function') {
                    return val + ''; // implicitly `toString` it
                }
                return val;
            }),
            urlQuery: `${API_ROOT}/${type}/query/multiple`,
        },
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json',
            // Authorization: ``,
        },
        responseType: 'blob',
    })
        .then((response) => {
            FileDownload(response.data, `report_${type}.pdf`);
            dispatch({
                type: DASHBOARD_EXPORT_SUCCESS,
            });
            return response.data;
        })
        .catch((error) => {
            dispatch({ type: DASHBOARD_EXPORT_FAILURE, error });
        });
};

export const getWorldCoordsData: ActionCreator<ThunkAction<Promise<Action>, IDashboardState, null, IDashboardAction>> =
    (id: string) => async (dispatch: Dispatch) => {
        dispatch({ type: COORDS_DATA_REQUEST });

        return await axios({
            method: 'GET',
            url: `https://api.npoint.io/${id}`,
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                Accept: 'application/json',
            },
        })
            .then((response) => {
                dispatch({
                    type: COORDS_DATA_SUCCESS,
                    payload: response.data,
                });
                return response.data;
            })
            .catch((error) => {
                dispatch({ type: COORDS_DATA_FAILURE, error });
            });
    };

export const dashboardActions: ActionCreatorsMapObject<
    ThunkAction<Promise<Action>, IDashboardState, null, IDashboardAction>
> = {
    // requestFileDownload,
    // successFileDownload,
    getDashboardData,
    getDashboardDataMultiple,
    // getFilterValues,
    setFilters,
    addFilter,
    reset,
    exportDashboardData,
    exportDashboard,
    getTimestamp,
    getWorldCoordsData,
};
